/* eslint-disable */

import HTTP from "./http";

export const requestInterceptor = function (config: any) {
  return config;
};

export const requestInterceptorError = function (error: any) {
  return Promise.reject(error);
};

export const responseInterceptor = function (response: any) {
  const data = response.data || { code: undefined };
  const code = data.code;
  const origin = window.location.origin;
  const pageURL = window.location.href;
  if (code && code == HTTP.NOT_LOGIN) {
    alert("登陆已失效");
    // window.location.href =
    //   origin + "/login" + "?redirect=" + encodeURIComponent(pageURL);
    throw new Error(data.msg);
  }
  return response;
};
export const responseInterceptorError = function (error: any) {
  return Promise.reject(error);
};
