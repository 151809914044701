/* eslint-disable  */
import axios from 'axios';
import { HEADERS } from './config';
import { requestInterceptor, requestInterceptorError, responseInterceptor, responseInterceptorError } from './interceptor';

/**
 * @description ajax请求 参数格式化方法
 * @param {Object} data
 * @param {Object} headers
 */

const getMethodGet = function (inst: any) {
  return async function (url: string, data: any = {}, config: any = {}) {
    inst.defaults.headers.Authorization = localStorage.getItem('ticketToken') ? localStorage.getItem('ticketToken') : "";
    data = { params: { ...data, t: +new Date() } };
    try {
      const res = await inst.get(url, data, config);
      return res.data;
    } catch (error: any) {
      throw new Error(error);
    }
  };
};

/**
 * @description 利用Axios实例封装Post请求
 * @param {AxiosInstance} inst
 */
const getMethodPost = function (inst: any) {
  return async function (url: string, data: any = {}, config: any = {}) {
    inst.defaults.headers.Authorization = localStorage.getItem('ticketToken') ? localStorage.getItem('ticketToken') : "";
    try {
      const res = await inst.post(url, data, config);
      return res.data;
    } catch (error: any) {
      throw new Error(error);
    }
  };
};
/**
 * @description 利用Axios实例封装Dlete请求
 * @param {AxiosInstance} inst
 */
const getMethodDelete = function (inst: any) {
  return async function (url: string, data: any = {}, config: any = {}) {
    data = { params: { ...data } };
    try {
      const res = await inst.delete(url, data, config);
      return res.data;
    } catch (error: any) {
      throw new Error(error);
    }
  };
};

/**
 * @description 根据传递过来的urlbase生成对应的axios实例
 * @param {String} urlbase
 */
const getInstance = function (urlbase: string) {
  const instance = axios.create({
    baseURL: urlbase,
    // withCredentials: true,
    headers: HEADERS
  });
  instance.interceptors.request.use(requestInterceptor, requestInterceptorError);
  instance.interceptors.response.use(responseInterceptor, responseInterceptorError);
  return instance;
};

/**
 * @description 工厂方法用于根据不同的urlbase生成不同的服务端接口服务
 * @param {String} urlbase
 */
export const getServer = function (urlbase: string) {
  const instance = getInstance(urlbase); // 获取实例
  const server = {
    get: getMethodGet(instance),
    post: getMethodPost(instance),
    delete: getMethodDelete(instance)
  };
  return server;
};

export const instance = axios.create();
