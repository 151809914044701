/* eslint-disable @typescript-eslint/no-explicit-any */
// import { getLocal } from "@/utils/storage";
// 服务端接口地址
const APIS: any = {
  // 本地环境
  development: {
    pcUrl: "https://test-api.wangyuanju.net/wx/",
  },
  // 测试环境
  release: {
    pcUrl: "https://test-api.wangyuanju.net/wx/",
  },
  // 预发布
  pre: {
    pcUrl: "https://pre-api.wangyuanju.net/wx/",
  },
  // 生产环境
  production: {
    pcUrl: "https://api.wangyuanju.net/wx/",
  },
};

const ENV = process.env.VUE_APP_ENV || "development";
export const BASE_URL = APIS[ENV];

export const HEADERS = {
  "Content-Type": "application/json;charset=UTF-8",
  resourceType: '2',
  Authorization: localStorage.getItem('ticketToken') ? localStorage.getItem('ticketToken') : "",
};
