/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { getServer } from "./config/base";
import { BASE_URL } from "./config/config";
import { CreateOrderParams, SendMobileParams, ticketInfoParams, emailLoginParams } from "@/types/yijifen";


const yijifenServer = getServer(BASE_URL.pcUrl);



/**
 * 创建免费领票
 * @param mobile 手机号
 * @param code 验证码
 * @param voucher  凭证图片
 */

export const createTicketOrderApi = ({
  mobile,
  code,
  voucher,
}: CreateOrderParams) => {

  return yijifenServer.post(
    "/ticket/apply/create-ticket-apply-order",
    {
      mobile,
      code: process.env.VUE_APP_ENV === 'development' ? '123456' : code,
      voucher,
    },
  );
};
export const emailLogin = ({
  email,
  password
}: emailLoginParams) => {
  return yijifenServer.post(
    "/user_v2/email-login-v2",
    {
      email,
      password
    },
  );
};

export const getTicketInfo = ({
  ticketCode
}: ticketInfoParams) => {
  return yijifenServer.get(
    "/ticket/pay/get-ticket-pay-order-by-code",
    {
      ticketCode
    },
  );
};

export const submitTicketOrder = ({
  ticketCode
}: ticketInfoParams) => {
  return yijifenServer.get(
    "/ticket/pay/delivery-ticket-pay-order",
    {
      ticketCode
    },
  );
};

export const loginOut = () => {
  return yijifenServer.post(
    "/user/logout"
  );
};

/**
* 发送验证码
* @param account 手机号
* @param countryCode 86
* @param func  发送验证码的功能类型：1-快捷登录 3-注册 4-找回密码 7-注销 8-创建订单
* @param way  1-发送邮箱验证码 2-发送手机验证码
*/

export const sendCodeApi = ({
  account,
  // countryCode,
  // func,
  // way,
}: SendMobileParams) => {

  return yijifenServer.post(
    "/code/sendCode",
    {
      account,
      countryCode: '86',
      func: 8,
      way: 2,
    },
  );
};



/**
 * 创建免费领票
 */

export const isEnableTicketOrderApi = () => {
  return yijifenServer.get(
    "/ticket/apply/check-ticket-apply-order-enable",
  );
};


/**
 * 门票系统Token获取
 * @param mobile 手机号
 */

export const getTicketTokenApi = (mobile: string) => {

  return yijifenServer.get(
    "/ticket/get-ticket-web-token",
    { mobile }
  );
};